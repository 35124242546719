import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { headerNavItems } from '@/data/navigation';

interface ComponentProps {
  isOpen: boolean;
  toggle: () => void;
}

interface ContainerWrapperProps {
  isOpen: boolean;
}

const ContainerWrapper = styled.div<ContainerWrapperProps>`
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: ${(props) => (props.isOpen ? `0` : `-100%`)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: right 0.3s ease-out;
  background-color: ${(props) => props.theme.mobileNav.background};
  z-index: 99;
  @media (min-width: 769px) {
    display: none;
  }
`;

const NavLinkMobile = styled(Link)`
  display: inline-flex;
  margin: 1rem 0;
  padding: 0 1.5rem;
  color: ${(props) => props.theme.mobileNav.link};
  cursor: pointer;
  font-weight: bold;
  &:visited {
    color: ${(props) => props.theme.mobileNav.link};
  }
  &.active,
  &:active {
    color: ${(props) => props.theme.mobileNav.linkHover};
  }
  &:hover {
    color: ${(props) => props.theme.mobileNav.linkHover};
  }
`;

const MobileNavModal: React.FC<ComponentProps> = ({ isOpen, toggle }) => {
  // USE EFFECT
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = `hidden`;
    } else {
      document.body.style.overflow = `unset`;
    }
  }, [isOpen]);

  return (
    <ContainerWrapper isOpen={isOpen}>
      {headerNavItems.map(
        (item) =>
          item.show && (
            <NavLinkMobile key={item.id} to={item.url} onClick={toggle}>
              {item.title}
            </NavLinkMobile>
          ),
      )}
    </ContainerWrapper>
  );
};

export default MobileNavModal;
