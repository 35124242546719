import React from 'react';
import styled from '@emotion/styled';
import { BaseComponentProps } from '@/types';

const PageContainerWrapper = styled.main`
  flex: 1;
  margin-top: 4.5rem;
`;

const PageContainer: React.FC<BaseComponentProps> = ({ children }) => (
  <PageContainerWrapper>{children}</PageContainerWrapper>
);

export default PageContainer;
