import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { useTheme } from '@emotion/react';
import { Container, Logo } from './elements';
import { footerNavItems } from '@/data/navigation';

const ComponentContainer = styled.footer`
  background-color: ${(props) => props.theme.footer.background};
`;
const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  @media (min-width: 768px) {
    margin: 3rem 0;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    margin: 1.5rem 0;
  }
`;
const NavWrapper = styled.nav`
  display: inline-flex;
  align-items: center;
`;
const NavLink = styled(Link)`
  display: inline-flex;
  padding: 0 1.5rem;
  color: ${(props) => props.theme.footer.link};
  cursor: pointer;
  font-size: 0.9rem;
  &:visited {
    color: ${(props) => props.theme.footer.link};
  }
  &:active {
    color: ${(props) => props.theme.footer.linkHover};
  }
  &:hover {
    color: ${(props) => props.theme.footer.linkHover};
  }
  @media (max-width: 768px) {
    //display: none;
  }
`;
const NavALink = styled.a`
  display: inline-flex;
  padding: 0 1.5rem;
  color: ${(props) => props.theme.footer.link};
  cursor: pointer;
  font-size: 0.9rem;
  &:visited {
    color: ${(props) => props.theme.footer.link};
  }
  &:active {
    color: ${(props) => props.theme.footer.linkHover};
  }
  &:hover {
    color: ${(props) => props.theme.footer.linkHover};
  }
  @media (max-width: 768px) {
    //display: none;
  }
`;
const FooterBar = styled.div`
  color: ${(props) => props.theme.footer.barText};
  background-color: ${(props) => props.theme.footer.barBackground};
  font-size: 0.8rem;
`;
const FooterBarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
const FooterBarItem = styled.div`
  @media (max-width: 576px) {
    margin: 0.5rem 0;
  }
`;
const CreatedByLink = styled.a`
  color: ${(props) => props.theme.footer.barLink};
  padding-left: 0.2rem;
  &:hover {
    color: ${(props) => props.theme.footer.barLinkHover};
  }
`;

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <ComponentContainer>
      <Container>
        <BoxWrapper>
          <Logo
            to="/"
            color={theme.footer.link}
            secondaryColor={theme.palette.primary.light}
            hoverColor={theme.footer.linkHover}
          />
          <NavWrapper>
            {footerNavItems.map((item) => {
              if (item.type === `internal` && item.show) {
                return (
                  <NavLink key={item.id} to={item.url} activeClassName="active">
                    {item.title}
                  </NavLink>
                );
              }
              if (item.show) {
                return (
                  <NavALink key={item.id} href={item.url} target={`_blank`}>
                    {item.title}
                  </NavALink>
                );
              }
              return null;
            })}
          </NavWrapper>
        </BoxWrapper>
      </Container>
      <FooterBar>
        <Container>
          <FooterBarInner>
            <FooterBarItem>
              © Copyright 2021. All Rights Reserved.
            </FooterBarItem>
            <FooterBarItem>
              Created by
              <CreatedByLink href="https://redcranesolutions.com">
                Red Crane Co.,Ltd
              </CreatedByLink>
            </FooterBarItem>
          </FooterBarInner>
        </Container>
      </FooterBar>
    </ComponentContainer>
  );
};

export default Footer;
