import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import Container from './Container';

import { isColor } from '@/utils/helpers';

interface ComponentProps {
  bgColor?: string;
  noPadding?: boolean;
  fullwidth?: boolean;
  children: string | ReactNode;
}

interface SectionContainerProps {
  bgColor?: string;
  noPadding: boolean;
}

const SectionContainer = styled.section<SectionContainerProps>`
  text-align: center;
  background-color: ${(props) =>
    isColor(props.bgColor)
      ? props.bgColor
      : props.theme.backgrounds[props.bgColor]};
  padding: ${(props) => (props.noPadding ? 0 : `10px`)};

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    padding: ${(props) => (props.noPadding ? 0 : `1rem 1.0875rem`)};
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    padding: ${(props) => (props.noPadding ? 0 : `5rem 1.0875rem`)};
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  }
`;

const Section: React.FC<ComponentProps> = ({
  noPadding,
  fullwidth,
  bgColor,
  children,
}) => (
  <SectionContainer noPadding={noPadding} bgColor={bgColor}>
    <Container fullwidth={fullwidth}>{children}</Container>
  </SectionContainer>
);

Section.defaultProps = {
  bgColor: `default`,
  fullwidth: false,
  noPadding: false,
};

export default Section;
