const primaryDark = `#840606`;
const primary = `#db0000`;
const primaryLight = `#ee594f`;
const primaryLighter = `#fdf6f5`;

const theme = {
  breakpoints: {
    xs: `576px`,
    sm: `768px`,
    md: `992px`,
    lg: `1200px`,
  },
  palette: {
    primary: {
      text: `#333333`,
      contrastText: `#ffffff`,
      lighter: primaryLighter,
      light: primaryLight,
      main: primary,
      dark: primaryDark,
    },
    gray: {
      lighter: `#efefef`,
      light: `#cccccc`,
      main: `#a8a8a8`,
      dark: `#626262`,
    },
  },
  border: {
    gray: `#a8a8a8`,
  },
  text: {
    default: `#333333`,
    color: `#333333`,
  },
  backgrounds: {
    default: `transparent`,
    primary,
    primaryLight,
    primaryLighter,
    grayLight: `#efefef`,
  },
  mobileNav: {
    link: `#ffffff`,
    linkHover: `#333333`,
    background: primary,
  },
  header: {
    link: `#ffffff`,
    linkHover: primary,
    background: `#333333`,
  },
  footer: {
    link: `#ffffff`,
    linkHover: `#333333`,
    background: primary,
    barText: `#626262`,
    barLink: `#626262`,
    barLinkHover: primary,
    barBackground: `#333333`,
  },
};

export default theme;
