import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Container } from '@/components/elements';
import { isColor } from '@/utils/helpers';

interface ComponentProps {
  //  noPadding?: boolean;
  fullwidth?: boolean;
  bgImage?: ReactElement;
  bgColor?: string;
  title: string;
  subtitle?: string;
  image?: ReactElement;
}

interface PageHeaderContainerProps {
  bgColor: string;
}

const PageHeaderContainer = styled.div<PageHeaderContainerProps>`
  background-color: ${(props) =>
    isColor(props.bgColor)
      ? props.bgColor
      : props.theme.backgrounds[props.bgColor]};
  min-height: 300px;
  display: grid;
  place-items: flex-end;
`;
const BgImage = styled.div`
  grid-area: 1 / 1;
  z-index: 9;
  overflow: hidden;
`;
const PageHeaderInner = styled.div`
  background-repeat: no-repeat;
  background-position: 80% 50%;
  height: 100%;
  grid-area: 1 / 1;
  z-index: 10;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.palette.primary.contrastText};
  text-shadow: 2px 2px 4px #333333;
`;
const Subtitle = styled.p`
  color: ${(props) => props.theme.palette.primary.contrastText};
  text-shadow: 2px 2px 4px #333333;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 600px;
  }
`;

const IconWrapper = styled.div`
  width: auto;
  margin: 10px 20px;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0;
    width: 100%;
    max-width: 400px;
  }
`;

// eslint-disable-next-line no-lone-blocks
{
  /*
  const BreadCrumbContainer = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
`;
*/
}

const PageHeader: React.FC<ComponentProps> = ({
  fullwidth,
  bgImage,
  bgColor,
  //  breadcrumb,
  title,
  subtitle,
  image,
}) => (
  <PageHeaderContainer bgColor={bgColor}>
    {bgImage && <BgImage>{bgImage}</BgImage>}
    <PageHeaderInner>
      <Container fullwidth={fullwidth}>
        <InnerContainer>
          {
            //  <BreadCrumbContainer>{breadcrumb}</BreadCrumbContainer>
          }
          <ContentContainer>
            <div>
              <Title>{title}</Title>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </div>

            {image && <IconWrapper>{image}</IconWrapper>}
          </ContentContainer>
        </InnerContainer>
      </Container>
    </PageHeaderInner>
  </PageHeaderContainer>
);

PageHeader.defaultProps = {
  bgColor: `default`,
  fullwidth: false,
  //  noPadding: false,
  subtitle: null,
  bgImage: null,
  image: null,
};

export default PageHeader;
