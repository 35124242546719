import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { BaseComponentProps } from '@/types';
import themeDefault from '@/themes/default';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { AppContainer, MobileNavModal } from '@/components/elements';

const Layout: React.FC<BaseComponentProps> = (props) => {
  const { children } = props;

  const [isMobileModalOpen, setMobileModalOpen] =
    React.useState<boolean>(false);

  return (
    <ThemeProvider theme={themeDefault}>
      <AppContainer>
        <Header
          isMobileModalOpen={isMobileModalOpen}
          toggleMobileModal={() => setMobileModalOpen(!isMobileModalOpen)}
        />
        {children}
        <Footer />
      </AppContainer>
      <MobileNavModal
        isOpen={isMobileModalOpen}
        toggle={() => setMobileModalOpen(!isMobileModalOpen)}
      />
    </ThemeProvider>
  );
};

export default Layout;
