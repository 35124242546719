import React from 'react';
import styled from '@emotion/styled';

const App = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  & svg .primary-main {
    fill: ${(props) => props.theme.palette.primary.main};
  }
  & svg .primary-light {
    fill: ${(props) => props.theme.palette.primary.light};
  }
  & svg .primary-lighter {
    fill: ${(props) => props.theme.palette.primary.lighter};
  }
  & svg .primary-dark {
    fill: ${(props) => props.theme.palette.primary.dark};
  }
  & svg .primary-dark-4 {
    fill: ${(props) => props.theme.palette.primary.dark};
    opacity: 0.04;
  }
`;

const AppContainer: React.FC = ({ children }) => (
  <App>{children}</App>
);

export default AppContainer;
