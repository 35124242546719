import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

interface ComponentProps {
  children: ReactNode;
  noPadding?: boolean;
  fullwidth?: boolean;
}

const ContainerWrapper = styled.div<ComponentProps>`
  margin: auto;
  padding: ${(props) => (props.noPadding ? 0 : `10px`)};

  // Small devices (landscape phones, 576px and up)
  @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
    //max-width: 576px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: ${(props) =>
      props.fullwidth ? `auto` : props.theme.breakpoints.sm};
    padding: ${(props) => (props.noPadding ? 0 : `1rem 1.0875rem`)};
    margin: 0 auto;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: ${(props) =>
      props.fullwidth ? `auto` : props.theme.breakpoints.md};
    margin: 0 auto;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    max-width: ${(props) =>
      props.fullwidth ? `auto` : props.theme.breakpoints.lg};
    margin: 0 auto;
  }
`;

const Container: React.FC<ComponentProps> = ({
  children,
  noPadding,
  fullwidth,
}) => (
  <ContainerWrapper noPadding={noPadding} fullwidth={fullwidth}>
    {children}
  </ContainerWrapper>
);

Container.defaultProps = {
  noPadding: false,
  fullwidth: false,
};

export default Container;
