import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Hamburger from 'hamburger-react';

import { useTheme } from '@emotion/react';
import { Container, Logo } from './elements';
import { headerNavItems } from '@/data/navigation';

interface ComponentProps {
  isMobileModalOpen: boolean;
  toggleMobileModal: () => void;
}
interface HeaderWrapperProps {
  show: boolean;
}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
  background-color: ${(props) => props.theme.header.background};
  z-index: 100;
  position: fixed;
  top: ${(props) => (props.show ? 0 : `-4.5rem`)};
  transition: top 0.3s ease-out;
  width: 100%;
  -webkit-box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.8);
  box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.8);
`;
const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
  @media (max-width: 768px) {
    padding: 0 0.8rem;
  }
`;
const NavWrapper = styled.nav`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;
const NavLink = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 1.5rem;
  color: ${(props) => props.theme.header.link};
  cursor: pointer;
  font-weight: bold;
  height: 100%;

  &:visited {
    color: ${(props) => props.theme.header.link};
  }
  &.active:after {
    content: '';
    position: absolute;
    left: calc(50% - 8px);
    top: 0;
    width: 0;
    height: 0;
    border-top: solid 8px #ffffff;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }

  &:hover {
    color: ${(props) => props.theme.header.linkHover};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavALink = styled.a`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 1.5rem;
  color: ${(props) => props.theme.header.link};
  cursor: pointer;
  font-weight: bold;
  height: 100%;

  &:visited {
    color: ${(props) => props.theme.header.link};
  }
  &.active:after {
    content: '';
    position: absolute;
    left: calc(50% - 8px);
    top: 0;
    width: 0;
    height: 0;
    border-top: solid 8px #ffffff;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }

  &:hover {
    color: ${(props) => props.theme.header.linkHover};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerWrapper = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

const Header: React.FC<ComponentProps> = ({
  isMobileModalOpen,
  toggleMobileModal,
}) => {
  const theme = useTheme();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (isMobileModalOpen) {
      setVisible(true);
    } else {
      setVisible(
        (prevScrollPos > currentScrollPos &&
          prevScrollPos - currentScrollPos > 70) ||
          currentScrollPos < 250,
      );
      setPrevScrollPos(currentScrollPos);
    }
  };

  // USE EFFECT
  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll);
    return () => window.removeEventListener(`scroll`, handleScroll);
  }, [isMobileModalOpen, prevScrollPos, visible, handleScroll]);

  return (
    <HeaderWrapper show={visible}>
      <Container noPadding>
        <BoxWrapper>
          <Logo
            to="/"
            color={theme.header.link}
            secondaryColor={theme.palette.primary.main}
            hoverColor={theme.header.linkHover}
          />
          <NavWrapper>
            {headerNavItems.map((item) => {
              if (item.type === `internal` && item.show) {
                return (
                  <NavLink key={item.id} to={item.url} activeClassName="active">
                    {item.title}
                  </NavLink>
                );
              }
              if (item.show) {
                return (
                  <NavALink key={item.id} href={item.url} target={`_blank`}>
                    {item.title}
                  </NavALink>
                );
              }
              return null;
            })}
            <HamburgerWrapper>
              <Hamburger
                toggled={isMobileModalOpen}
                toggle={toggleMobileModal}
                color="#fff"
              />
            </HamburgerWrapper>
          </NavWrapper>
        </BoxWrapper>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
