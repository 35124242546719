import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import SiteLogo from '../../assets/svg/logo.svg';

interface LogoProps {
  to: string;
  color?: string;
  secondaryColor?: string;
  hoverColor?: string;
}

const LogoWrapper = styled(Link)`
  transition: 0.5s all;
  cursor: pointer;
`;
const LogoItem = styled(SiteLogo)`
  height: 40px;
  transition: 0.5s all;
  fill: ${(props) => props.color};
  & .primary-main {
    fill: ${(props) =>
      props.secondaryColor
        ? `${props.secondaryColor} !important`
        : props.theme.palette.primary.main};
  }
  &:hover {
    fill: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.palette.primary.main};
  }
`;

const Logo: React.FC<LogoProps> = ({
  to,
  color,
  secondaryColor,
  hoverColor,
}) => (
  <LogoWrapper to={to}>
    <LogoItem
      color={color}
      secondaryColor={secondaryColor}
      hoverColor={hoverColor}
    />
  </LogoWrapper>
);

Logo.defaultProps = {
  color: `#ffffff`,
  secondaryColor: null,
  hoverColor: null,
};

export default Logo;
