const navItems = [
  {
    id: 1,
    title: `Home`,
    url: `/`,
    type: `internal`,
    show: true,
  },
  {
    id: 2,
    title: `About`,
    url: `/about`,
    type: `internal`,
    show: true,
  },
  {
    id: 3,
    title: `Web Hosting`,
    url: `/hosting`,
    type: `internal`,
    show: true,
  },
  {
    id: 4,
    title: `Support`,
    url: `/support`,
    type: `internal`,
    show: false,
  },
  {
    id: 5,
    title: `Contact`,
    url: `/contact`,
    type: `internal`,
    show: true,
  },
  {
    id: 6,
    title: `Login`,
    url: `/login`,
    type: `internal`,
    show: true,
  },
  {
    id: 7,
    title: `Status`,
    url: `https://stats.uptimerobot.com/9B3ZEszMyJ`,
    type: `external`,
    show: true,
  },
];

export const headerNavItems = navItems;
export const footerNavItems = navItems;
export default navItems;
